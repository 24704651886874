// dataChangeMixin.ts
import Vue, { VueConstructor } from 'vue';

// Interface for the mixin
interface DataChangeMixin extends Vue {
  watcherActivated: boolean;
  dataChanged(): void;
  resetWatcherActivated(): void;
}

// eslint-disable-next-line import/prefer-default-export
export const dataChangeMixin: VueConstructor<DataChangeMixin> = Vue.extend({
  data() {
    return {
      watcherActivated: false
    };
  },
  props: [
    'dataUpdated'
  ],
  watch: {
    $data: {
      deep: true,
      handler(this: DataChangeMixin) {
        // Check if resetWatcherActivated was called
        // eslint-disable-next-line no-underscore-dangle
        if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
          if (this.watcherActivated) {
            this.dataChanged();
          } else {
            this.watcherActivated = true;
          }
        }
      }
    }
  },
  methods: {
    dataChanged(this: DataChangeMixin) {
      this.$emit('some-data-changed', true);
    },
    resetWatcherActivated(this: DataChangeMixin) {
      // Set a flag to indicate that resetWatcherActivated was called
      // eslint-disable-next-line no-underscore-dangle
      (this as any)._resettingWatcher = true;
      this.watcherActivated = false;
      // After resetting, remove the flag
      this.$nextTick(() => {
        // eslint-disable-next-line no-underscore-dangle
        delete (this as any)._resettingWatcher;
      });
    },
    startWatcherActivated(this: DataChangeMixin) {
      // Set a flag to indicate that startWatcherActivated was called
      // eslint-disable-next-line no-underscore-dangle
      (this as any)._startingWatcher = true;
      this.watcherActivated = true;
      // After starting, remove the flag
      this.$nextTick(() => {
        // eslint-disable-next-line no-underscore-dangle
        delete (this as any)._startingWatcher;
      });
    },
  }
});
